import React, { Component, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state to trigger fallback UI on error
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Log error details, you could log this to an external service
    console.error('Caught an error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Reload the page if an error has occurred
      window.location.reload();
      return null; // Return null while the page reloads
    }

    // Render children if there’s no error
    return this.props.children;
  }
}

export default ErrorBoundary;
